.LoadingOverlay {
  align-items: center;
  background: #1a3464;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.status {
  font-size: 1.125rem;
  margin-top: 0.625rem;
}