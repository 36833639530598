.Button {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 0.938rem;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 150px;
  padding: 0.75rem 1.5rem;
  transition: 0.3s linear;
}