.App {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  /* padding: 1rem; */
}

.wrapper {
  /* background: #191919;
  border: 1px solid #fff; */
  /* border-radius: 5px; */
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100%; */
  /* justify-content: center; */
  /* min-height: 45%; */
  /* min-width: 33.33%; */
  /* padding: 2rem 4rem; */
  max-height: 100%;
  max-width: 700px;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
  width: 100%;
}
