.Register {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Register img {
  width: 100px;
}

.Register h1 {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0.625rem 0;
  text-align: center;
}

.message {
  font-size: 1.125rem;
  font-style: oblique;
  margin-bottom: 0.625rem;
  text-align: center;
  width: 85%;
}

.Register h2 {
  color: #7a7a7a;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 500;
  margin: 2rem 0 2rem 0;
  text-align: center;
}

.Register form {
  margin-bottom: 1.875rem;
  /* min-width: 500px; */
  width: 100%;
}

.Register form input {
  border: none;
  border-radius: 5px;
  flex-grow: 1;
  font-size: 1.125rem;
  font-style: oblique;
  padding: 0.625rem 0.938rem;
}

.Register form div {
  display: flex;
  flex-direction: column;
}

.Register form div:first-child {
  margin-bottom: 1rem;
}

/* .Register form div:first-child input:first-child,
.Register form div:first-child input:last-child,
.Register form div:last-child input {
  flex-grow: 1;
} */
/* .Register form div:first-child input:nth-child(2) {
  width: 40px;
  margin: 0 0.625rem;
} */
.Register form div:first-child input:first-child,
.Register form div:last-child input:first-child {
  margin-bottom: 1rem;
  /* margin-right: 1rem; */
}

.Register button:first-child {
  margin-bottom: 1rem;
  /* margin-right: 0.938rem; */
}

.Register > div:last-of-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.Register p {
  margin-top: 2rem;
}

.Register a {
  color: #a5b11f;
  text-decoration: none;
}

@media screen and (min-width: 35.625rem) {
  .Register form div {
    flex-direction: row;
  }

  .Register form div:first-child input:first-child,
  .Register form div:last-child input:first-child {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .Register > div:last-of-type {
    flex-direction: row;
  }

  .Register button:first-child {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
