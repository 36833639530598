.PhoneInput {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #1a3464;
  overflow: hidden;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
}
.focus {
  border-color: #1E8ADC;
}

.PhoneInput input {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.output {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.output span {
  display: flex;
}

.output span:last-child {
  margin-right: 0;
}

@media screen and (min-width: 20rem) {
  .output span:first-child,
  .output span:nth-child(2) {
    margin-right: 1rem;
  }
}
