.Login {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Login img {
  width: 100px;
}

.Login h1 {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0.625rem 0;
}

.message {
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
}

.Login form {
  margin-bottom: 1.875rem;
}

.Login button {
  margin-bottom: 2rem;
}

.Login > div {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Login a {
  color: #a5b11f;
  text-decoration: none;
}
